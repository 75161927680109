<div class="container">
    <div class="form">
        <form [formGroup]="credentialsForm" (ngSubmit)="submitAuthRequest()">
            <label>Username
                <input formControlName="username">
            </label>
            <label>Password
                <input type="password" formControlName="password">
            </label>
            <button *ngIf="!isProcessing" class="submit-button" [disabled]="credentialsForm.invalid" type="submit">Login</button>
        </form>
    </div>
</div>