<nav>
  <div><a routerLink="/about">ABOUT</a></div>
  <div><a routerLink="/submit-terms">PARTICIPATE</a></div>
</nav>
<div *ngIf="info" class="terms-details">
<div class=heading>
    <div (click)="goBack()" class="back-button">
        <span>[x] close</span>
    </div>
    <h1>{{info.title || ''}}</h1>
</div>

  <p class="info">
    <markdown >
      {{info.description}}
    </markdown>
  </p>
  <div class="extra-info">
    <div class="item-container">
      <div *ngFor = "let item of info.infoTiles"class="item">
        <div class="text-container">
        <h2 class="title">{{item.title}}</h2>
        <p class="description">
          {{item.text}}
        </p>
      </div>
        <h2 class="number">{{item.number}}</h2>
      </div>
    </div>
  </div>
</div>