
<nav>
    <a routerLink="/average-terms"><div>TERMS</div></a>
    <a routerLink="/submit-terms"><div>PARTICIPATE</div></a>
    <div (click)="goBack()">BACK</div>
  </nav>
<div *ngIf="!isSubmitted" class="agreement-form">
    <form [formGroup]="agreement" (ngSubmit)="onSubmit()">
        <label>
            * - mandatory fields
        </label>
        <label>
            Did you receive an advance?*
            <select formControlName="advanceReceived">
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <span class="text-danger">{{ showError('advanceReceived') }}</span>
        </label>

        <label *ngIf="agreement.value.advanceReceived === 'Yes'">
            Advance amount:
            <input type="string" min="0" formControlName="advanceAmount">
            <span class="text-danger">{{ showError('advanceAmount') }}</span>
        </label>

        <label *ngIf="agreement.value.advanceReceived === 'Yes'">
            Is the advance recoupable by the publisher?
            <select formControlName="advanceRecoupable"> 
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <span class="text-danger">{{ showError('advanceRecoupable') }}</span>
        </label>

        <label *ngIf="agreement.value.advanceReceived === 'Yes'">
            Type of Payment:
            <select formControlName="advancePaymentType">            
                <option value="Milestones">Milestones</option>
                <option value="Lump Sum">Lump Sum</option>
            </select>
            <span class="text-danger">{{ showError('advancePaymentType') }}</span>
        </label>

        <label>
            Royalty percentage paid to the developer?*
            <input type="number" max="100" min="0" formControlName="developerRoyalty">
            <span class="text-danger">{{ showError('developerRoyalty') }}</span>
        </label>

        <label *ngIf="agreement.value.advanceReceived === 'Yes' && agreement.value.advanceRecoupable === 'Yes'">
            Does the royalty percentage change during/after advance recoup?
            <select formControlName="royaltyChange">               
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <span class="text-danger">{{ showError('royaltyChange') }}</span>
        </label>

        <label *ngIf="agreement.value.royaltyChange === 'Yes' && agreement.value.advanceReceived === 'Yes'">
            Royalty percentage rate during advance recoup:
            <input type="number" max="100" min="0" formControlName="developerRoyaltyDuringRecoup">
            <span class="text-danger">{{ showError('developerRoyaltyDuringRecoup') }}</span>
        </label>

        <label *ngIf="agreement.value.royaltyChange === 'Yes' && agreement.value.advanceReceived === 'Yes'">
            Royalty percentage rate after advance recoup:
            <input type="number" max="100" formControlName="developerRoyaltyAfterRecoup">
            <span class="text-danger">{{ showError('developerRoyaltyAfterRecoup') }}</span>
        </label>

        <label *ngIf="agreement.value.royaltyChange === 'Yes' && agreement.value.advanceReceived === 'Yes'">
            Additional rate (if any):
            <div *ngFor="let entry of agreement.value.developerRoyaltyVariation; index as i;">
                <span>Royalty Rate {{i+1}}: </span><span>{{entry}}</span>
            </div>
            <div class="control-button" (click)="toggleRoyaltyEntryField()" *ngIf="!addRoyaltyEntryActive">Add</div>
            <input *ngIf="addRoyaltyEntryActive" type="number" formControlName="royaltyVariationInput">
            <div class="control-button" (click)="toggleRoyaltyEntryField()" *ngIf="addRoyaltyEntryActive">Cancel</div>
            <div class="control-button" (click)="addRoyaltyEntry(agreement.value.royaltyVariationInput)"
                *ngIf="addRoyaltyEntryActive">Add</div>
        </label>

        <label *ngIf="agreement.value.advanceReceived === 'Yes' && agreement.value.advanceRecoupable === 'Yes'">
            Is the advance fully recouped before any royalty paid to developer?
            <select formControlName="advanceRecoupableBeforeRoyalty">               
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <span class="text-danger">{{ showError('advanceRecoupableBeforeRoyalty') }}</span>
        </label>

        <label>
            Who owns the game IP?*
            <select formControlName="ipOwnership">               
                <option value="Developer">Developer</option>
                <option value="Publisher">Publisher</option>
            </select>
            <span class="text-danger">{{ showError('ipOwnership') }}</span>
        </label>

        <label>
            Does the publisher have a right to publish a sequel in the future?*
            <select formControlName="publisherSequelRight">               
                <option value="Right to publish">Right to publish</option>
                <option value="Right to negotiate">Right to negotiate</option>
                <option value="No">No</option>
            </select>
            <span class="text-danger">{{ showError('publisherSequelRight') }}</span>
        </label>

        <label>
            Does publisher own Game IP in case of developer’s material breach of the agreement?
            <select formControlName="transferOnBreach">              
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <span class="text-danger">{{ showError('transferOnBreach') }}</span>
        </label>

        <label>
            Agreement Term*
            <select formControlName="agreementTermType">              
                <option value="Fixed">Fixed</option>
                <option value="Perpetual">Perpetual</option>
            </select>
            <span class="text-danger">{{ showError('agreementTermType') }}</span>
        </label>

        <label *ngIf="agreement.value.agreementTermType === 'Fixed'">
            Agreement duration in years:
            <input type="number" min="0" formControlName="agreementDuration">
            <span class="text-danger">{{ showError('agreementDuration') }}</span>
        </label>

        <label>
            Does developer have the right to audit the publisher's books?*
            <select formControlName="developerAuditRight">               
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
            <span class="text-danger">{{ showError('developerAuditRight') }}</span>
        </label>

        <div class="captcha">
            <re-captcha formControlName="recaptchaReactive" siteKey="6Lcv1iMaAAAAAJ02aOX75-IHrYhW1hQWLR4KgCxI">
            </re-captcha>
            This site is protected by reCAPTCHA and the Google <br/>
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
        <p>
            <input type="checkbox" id="accept" formControlName="termsAccepted" />
            <label for="accept" class="inline">I agree to the 
                <a target="_blank" routerLink="/terms-of-service">Terms of Use</a> and <a target="_blank" routerLink="/privacy-policy">Privacy Policy</a></label>
          </p>
        <button *ngIf="!isProcessing" class="submit-button" [disabled]="agreement.invalid" type="submit">Submit</button>
        <div *ngIf="isProcessing" class="submit-button">Processing ...</div>
    </form>
</div>
<div class="result-container">
    <div *ngIf="isSubmitted" class="result">
        <div *ngIf="isSubmitted && isSuccess" class="submit-message">Thank you for submitting your agreement terms!
        </div>
        <div *ngIf="isSubmitted && !isSuccess" class="submit-message">Failed to submit your terms. Please try again.
        </div>
        <div *ngIf="isSuccess" (click)="goToTermsPage()" class="back-button">Go Back</div>
        <div *ngIf="!isSuccess" (click)="tryAgain()" class="back-button">Try Again</div>
    </div>
</div>