import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/admin/services/authService/auth-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  credentialsForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  isProcessing = false;
  errorMessage = '';

  constructor(private authService: AuthService, private _router: Router) { }

  ngOnInit(): void {
  }

  async submitAuthRequest(){
    if(this.credentialsForm.valid){
      try{
        this.isProcessing = true;
        const isAuthenticated = await this.authService.sendAuthRequest(this.credentialsForm.value);
        this.isProcessing = false;
        if(isAuthenticated){
          this._router.navigate(['/control-panel']);
        }
        throw "Wrong Credentials";
      }
      catch(error){
        this.errorMessage = error;
      }
    }
  }

}
