import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DataService } from 'src/app/services/data.service';
import { CurrencyPipe, Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { stringify } from '@angular/compiler/src/util';

@UntilDestroy()
@Component({
  selector: 'app-submit-terms',
  templateUrl: './submit-terms.component.html',
  styleUrls: ['./submit-terms.component.sass']
})
export class SubmitTermsComponent implements OnInit {

  agreement = new FormGroup({
    advanceReceived: new FormControl('', [Validators.required]),
    advanceAmount: new FormControl(null),
    advanceRecoupable: new FormControl(''),
    advancePaymentType: new FormControl(''),
    developerRoyalty: new FormControl(null, [Validators.required, Validators.max(100), Validators.min(0)]),
    developerRoyaltyDuringRecoup: new FormControl(null, [Validators.max(100), Validators.min(0)]),
    developerRoyaltyAfterRecoup: new FormControl(null, [Validators.max(100), Validators.min(0)]),
    developerRoyaltyVariation: new FormControl([]),
    advanceRecoupableBeforeRoyalty: new FormControl(''),
    ipOwnership: new FormControl('', [Validators.required]),
    publisherSequelRight: new FormControl('', [Validators.required]),
    //  publisherSequelNegotiationRight: new FormControl('', [Validators.required]),
    agreementTermType: new FormControl('', [Validators.required]),
    agreementDuration: new FormControl(null, [Validators.min(0)]),
    developerAuditRight: new FormControl('', [Validators.required]),
    royaltyVariationInput: new FormControl(''),
    recaptchaReactive: new FormControl(null, Validators.required),
    transferOnBreach: new FormControl('', Validators.required),
    royaltyChange: new FormControl('', Validators.required),
    termsAccepted: new FormControl(false, Validators.requiredTrue)
  });


  addRoyaltyEntryActive = false;
  isSubmitted = false;
  isSuccess = false;
  isProcessing = false;

  VALIDATORS = {
    advanceAmount: [Validators.required, Validators.min(0)],
    advanceRecoupable: [Validators.required],
    advancePaymentType: [Validators.required],
    royaltyChange: [Validators.required],
    advanceRecoupableBeforeRoyalty: [Validators.required],
    developerRoyaltyDuringRecoup: [Validators.required, Validators.min(0), Validators.max(100)],
    developerRoyaltyAfterRecoup: [Validators.required, Validators.min(0), Validators.max(100)],
    agreementDuration: [Validators.required, Validators.min(0)]
  }

  constructor(private dataService: DataService, private router: Router, private location: Location, private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {
    this.setConditionalValidators('advanceReceived', 'Yes', ['advanceAmount', 'advanceRecoupable', 'advancePaymentType']);
    this.setConditionalValidators('advanceRecoupable', 'Yes', ['royaltyChange', 'advanceRecoupableBeforeRoyalty']);
    this.setConditionalValidators('royaltyChange', 'Yes', ['developerRoyaltyDuringRecoup', 'developerRoyaltyAfterRecoup']);
    this.setConditionalValidators('agreementTermType', 'Fixed', ['agreementDuration']);
 
 
    this.agreement.valueChanges.subscribe( form =>{
      console.log('Value changes:', form);
      if(form.advanceAmount){
        this.agreement.patchValue({
          advanceAmount: this.currencyPipe.transform(
            form.advanceAmount.replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1.0-0')
        }, {emitEvent: false});
      }
    });
  }

  setConditionalValidators(fieldName: string, condition: string, targetFields: string[]) {
    this.agreement.get(fieldName).valueChanges.pipe(untilDestroyed(this)).subscribe(
      value => {
        if (value === condition) {
          targetFields.forEach(field => this.agreement.get(field).setValidators(this.VALIDATORS[field]))
        }
        else {
          targetFields.forEach((field) => {
            this.agreement.get(field).clearValidators();
            this.agreement.get(field).updateValueAndValidity();
          })
        }
      }
    )
  }
  async onSubmit() {
    console.log(this.agreement.value);
    console.log(this.agreement.valid);
    if (this.agreement.valid) {
      try {
        if(this.agreement.value.advanceAmount){
          console.log('Transform amount back');

          this.agreement.patchValue({
            advanceAmount: Number(this.agreement.value.advanceAmount.replace(/[^0-9.-]+/g,""))
          }, {emitEvent: false});
        }
        this.isProcessing = true;
        const submission = this.trimSubmission(this.agreement.value);
        await this.dataService.submitAgreement({ agreement: submission });
        this.isSubmitted = true;
        this.isSuccess = true
        this.isProcessing = false;
      }
      catch (e) {
        console.log("FAILED!", e);
        this.isSubmitted = true;
      }
    }
  }

  trimSubmission(value) {
    delete value.royaltyVariationInput;
    delete value.recaptchaReactive;
    delete value.termsAccepted;
    return value;
  }

  toggleRoyaltyEntryField() {
    this.addRoyaltyEntryActive = !this.addRoyaltyEntryActive;
  }
  addRoyaltyEntry(entry) {
    if (!entry) {
      return;
    }
    const rv = this.agreement.value.developerRoyaltyVariation
    if (rv) {
      rv.push(entry);
    } else {
      this.agreement.value.developerRoyaltyVariation = [entry];
    }
    this.agreement.controls["royaltyVariationInput"].reset();
    this.toggleRoyaltyEntryField();

  }
  refreshPage() {
    window.location.reload();
  }
  goToTermsPage() {
    this.router.navigateByUrl('/average-terms');
  }
  goBack() {
    this.location.back();
  }
  tryAgain() {
    this.isSubmitted = false;
    this.isProcessing = false;
  }

  showError(field: string) {
    const errors: ValidationErrors = this.agreement.get(field).errors;
    if (errors && this.agreement.get(field).touched) {
      const displayErrors = [];
      for (const [key, value] of Object.entries(errors)) {
        displayErrors.push(this.mapError(key, value))
      }
      return displayErrors.join("\n");
    }
    return null;
  }

  mapError(error: any, value: any) {
    let result;
    switch (error) {
      case "required":
        result = "This field is required";
        break;
      case "max":
        result = `Max value is ${value.max}`;
        break;
      case "min":
        result = `Min value is ${value.min}`;
        break;
      default: result = error;
    }
    return result;
  }
}