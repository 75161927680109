<div *ngIf="data">
  <nav>
    <a routerLink="/about">
      <div>ABOUT</div>
    </a>
    <a routerLink="/submit-terms">
      <div>PARTICIPATE</div>
    </a>
    <a routerLink="/"> <div>HOME</div></a>
  </nav>
  <div class="container">
    <div *ngFor="let tile of data">
      <app-info-tile [tile]="tile"></app-info-tile>
    </div>
  </div>
  <div class="call-question">
    Are your terms market? Submit them to find out!
  </div>

  <div class="button-container">
    <div class="button">
      <a routerLink="/submit-terms">
        <span class="button-title">Submit your terms</span>
      </a>
    </div>
  </div>
  <div class="button-container">
    <div id="contact-button" class="button">
      <a href="https://voyerlaw.com" target="_blank">
        <span class="button-title">Contact a Video Game Lawyer</span>
      </a>
    </div>
  </div>
  <div class="disclaimer-container">
    <div>
      <a href="https://voyerlaw.com">
        <img
          id="signature"
          src="/assets/img/VoyerSignatureWhite.png"
          alt="voyer"
        />
      </a>
    </div>
  </div>
</div>
