<nav>
  <a routerLink="/submit-terms"><div>PARTICIPATE</div></a>
  <a routerLink="/average-terms"><div>TERMS</div></a>
  <a routerLink="/average-terms"><div>BACK</div></a>
</nav>
<div class="container">
  <p>
    <span class="bold">GOAL</span>   This project aims to crowd-source publishing
    agreement data in order to remove the mystique around publishing agreement
    terms and level the negotiation playing field between publishers and
    developers. And, if you don’t like the market-standard revealed by the data,
    to provide an impetus for change within industry contracting terms.
  </p>
  <p>
    <span class="bold">WHY</span>   Game developers are frequently at an
    informational disadvantage when negotiating with a publisher. Outside of
    lawyers and industry contacts, developers don’t have many resources to guide
    them through publishing agreement negotiations and to understand what terms
    are common or “market”.
  </p>
  <p>
    <span class="bold">WHO</span>   This project was developed by Voyer Law, a
    boutique firm that represents over 100 video game studios and whose lawyers
    have spoken at GDC, IGDA and contributed to reporting in PC Gamer,
   GamesIndustry.biz, the CBC and more.
  </p>

  <div class="button-container">
    <a href="https://voyerlaw.com" target="_blank">
      <div id="contact-button" class="button">
        <span class="button-title">Contact a Video Game Lawyer</span>
      </div>
    </a>
  </div>
</div>
<div class="powered-by">
  <a routerLink="/about">
    <span>Powered by </span><img class="logo" src="/assets/img/InvertedLogo.png">
  </a>
</div>
