import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const authURL = "https://vidgamepubbackend.azurewebsites.net/api/authenticateUser?";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authToken: string;
  
  constructor(private http: HttpClient, ) { }

  isAuthenticated(){
    if(this.authToken){
      return true;
    }
  }

  async sendAuthRequest(credentials: {username: string, password: string}){
    try{
      const response: any = await this.http.post(authURL, credentials).toPromise();
      this.authToken = response.authToken;
      return true;
    }
    catch(error){
      console.log(error);
      return false;
    }
  }
}
