import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { SubmitTermsComponent } from './modules/submitAgreement/submit-agreement/pages/submit-terms/submit-terms.component';
import { AverageTermsComponent } from './pages/average-terms/average-terms.component';
import { TermsDetailsComponent } from './pages/terms-details/terms-details.component';
import { AboutComponent } from './pages/about/about.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { AdminComponent } from './modules/admin/pages/admin/admin.component';
import { FullAgreementComponent } from './modules/admin/pages/full-agreement/full-agreement.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { LoginComponent } from './modules/admin/pages/login/login.component';



const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'submit-terms', component: SubmitTermsComponent },
  { path: 'average-terms', component: AverageTermsComponent },
  { path: 'terms-details/:id', component: TermsDetailsComponent },
  { path: 'full-agreement/:id', component: FullAgreementComponent },
  { path: 'about', component: AboutComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'terms-of-service', component: TermsOfServiceComponent},
  { path: 'control-panel', component: AdminComponent, canActivate: [AuthGuardService]},
  { path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
