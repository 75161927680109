<div class="container">
    <div *ngIf="!isAgreementOpen" class="grid-container">      
        <div class="grid-headers">
            <div (click)="changeFilterBy('New')" [ngClass]="{'active': filterBy === 'New', 'grid-button': true }">New
            </div>
            <div (click)="changeFilterBy('Approved')"
                [ngClass]="{'active': filterBy === 'Approved', 'grid-button': true }">Approved</div>
            <div (click)="changeFilterBy('Rejected')"
                [ngClass]="{'active': filterBy === 'Rejected', 'grid-button': true }">Rejected</div>
            <div (click)="changeFilterBy('All')" [ngClass]="{'active': filterBy === 'All', 'grid-button': true }">All
            </div>
        </div>
        <button (click)="refresh()" [disabled]="isRefreshing" class="button-calc">Refresh</button>
        <div class="agreement-grid"></div>
        <div class="grid agreement-headers">
            <div class="column1">Submission Date</div>
            <div class="column2">Advance</div>
            <div class="column3">IP ownership</div>
            <div class="column4">Term Type</div>
        </div>
        <div class="no-submissions" *ngIf="!newSubmissionsPresent && filterBy === 'New'">No New submissions</div>
        <div *ngFor="let agreement of agreements | filterAgreements: filterBy">
            <div routerLink="/full-agreement/{{agreement.id}}" class="grid agreement-row">
                <div class="column1">{{agreement.submissionDate | date:"MM/dd/yyyy" }}</div>
                <div class="column2">{{agreement.advanceAmount | currency}}</div>
                <div class="column3">{{agreement.ipOwnership}}</div>
                <div class="column4">{{agreement.agreementTermType}}</div>
            </div>
        </div>
    </div>
    <button (click)="calculateAverages()" [disabled]="isCalculating" class="button-calc">Calculate Averages</button>
    <div *ngIf = "calcResult">{{calcResult}}</div>
</div>