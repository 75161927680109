import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-full-agreement',
  templateUrl: './full-agreement.component.html',
  styleUrls: ['./full-agreement.component.sass']
})
export class FullAgreementComponent implements OnInit {
  fullAgreement: any
  isUpdating = false;

  constructor(private location: Location,
    private route: ActivatedRoute,
    private dataService: DataService) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.dataService.getOneAgreement(id).then((response) => {
      this.fullAgreement = response;
      console.log(this.fullAgreement);
      if(!this.fullAgreement){
        this.goBack();
      }
    }); 
  }
  async changeStatus(status){
    this.isUpdating = true;
    await this.dataService.changeStatus(status, this.fullAgreement.id);
    this.isUpdating = false;
    this.fullAgreement.status = status;
  }
  goBack() {
    this.location.back();
  }

}
