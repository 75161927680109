import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-terms-details',
  templateUrl: './terms-details.component.html',
  styleUrls: ['./terms-details.component.sass']
})
export class TermsDetailsComponent implements OnInit {
   info: any;

  constructor(private location: Location,
              private route: ActivatedRoute,
              private dataService: DataService) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.dataService.getOneItem(id).then((response) => {
      const data = response;
      this.info = { ...data.detailedInfo, id: id, title: data.tileInfo.title};
    });
  }

  goBack() {
    this.location.back();
  }

}
