import { Component, OnInit } from '@angular/core';
import { TileData } from 'src/app/models/TileData';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-average-terms',
  templateUrl: './average-terms.component.html',
  styleUrls: ['./average-terms.component.sass']
})
export class AverageTermsComponent implements OnInit {
  data: TileData[];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
   this.dataService.getData().then((response)=>{
     console.log("response: ", response);
     this.data = response.data;
   })
  }

}

