import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  filterBy: string = "New";
  
  constructor( ) { }

}
