import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AverageTermsComponent } from './pages/average-terms/average-terms.component';
import { InfoTileComponent } from './components/info-tile/info-tile.component';
import { TermsDetailsComponent } from './pages/terms-details/terms-details.component';
import { AboutComponent } from './pages/about/about.component';
import { FooterComponent } from './components/footer/footer.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { MarkdownModule } from 'ngx-markdown';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { LoginComponent } from './modules/admin/pages/login/login.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { SubmitAgreementModule } from './modules/submitAgreement/submit-agreement.module';
import { AdminDashboardModule } from './modules/admin/admin-dashboard/admin-dashboard.module';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    AverageTermsComponent,
    InfoTileComponent,
    TermsDetailsComponent,
    AboutComponent,
    FooterComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MarkdownModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    SubmitAgreementModule,
    AdminDashboardModule,
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeOsCMaAAAAACvri7dOlZQ8KXE-ZNKXYFRELbTH" },
    AuthGuardService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
