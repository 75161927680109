<a routerLink="/terms-details/{{tile.id}}">
<div class="tile">
  
    <div class="title">{{ tile.tileInfo.title }}</div>
    <div class="text">{{ tile.tileInfo.text }}</div>
    <div class="subtitle">
      {{ tile.tileInfo.number || "" }}
    </div>
    <div class="subtitle-text">{{tile.tileInfo.numberText}}</div>
  </div>
</a>
