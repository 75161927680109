import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAgreements'
})
export class FilterAgreementsPipe implements PipeTransform {

  transform(agreements: any[], filterBy: string): any[] {
    if (!agreements) return [];
    if (!filterBy || filterBy === "All") return agreements;
    return agreements.filter(it =>
      it.status === filterBy)
  }
}
