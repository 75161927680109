<div *ngIf="data">
<nav>
  <a routerLink="/about"><div>ABOUT</div></a>
</nav>
<div class="container">
  <div class="tile">
    <div class="question">
      Signing a contract with a publisher?
    </div>

    <div class="button-container">
      <a routerLink="/submit-terms">
        <div class="button">
          <span class="button-title">Submit your terms</span>
        </div>
      </a>
    </div>
  </div>
  <div class="tile">
    <div class="data-container">
      <div class="data">
        <div class="data-title">{{data.meanAdvance || "318,000" | currency}}</div>
        <div class="data-text">
          Average advance paid by a publisher to a video game developer.
        </div>
      </div>
      <div class="data">
        <div class="data-title">{{data.avgDeveloperRoyalty  | number : '1.0-0' || "30"}}%</div>
        <div class="data-text">
          Average revenue share paid by a publisher to a video game developer.
        </div>
      </div>
      <div class="data">
        <div class="data-title">{{ data.developerIPOwnershipPercentage * 100  | number : '1.0-0' || "81"}}%</div>
        <div class="data-text">
          How often a video game developer retains ownership of the game.
        </div>
      </div>
    </div>

    <div class="button-container">
      <a routerLink="/average-terms">
        <div class="button">
          <span class="button-title">Find out more</span>
        </div>
      </a>
    </div>

    <div class="powered-by">
      <a routerLink="/about">
        <span>Powered by </span><img class="logo" src="/assets/img/InvertedLogo.png">
      </a>
    </div>
  </div>
</div>
</div>