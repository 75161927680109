<nav>
  <div><a routerLink="/">HOME</a></div>
  <div><a routerLink="/submit-terms">PARTICIPATE</a></div>
  <a routerLink="/average-terms"><div>TERMS</div></a>
  <div><a routerLink="/about">ABOUT</a></div>
</nav>
<div class="container">
  <h1>Privacy Policy</h1>
  <p>
    This Privacy Policy applies to videogamepublishing.com, including
    subdomains, (the “Website”) and details the information Voyer Law
    Corporation (“Voyer Law”) collects, uses and/or discloses to third parties
    about you.
  </p>
  <p>
    The Privacy Policy addresses two kinds of information: (1) Personal
    Information; and (2) Anonymous Information. Personal Information identifies
    you as an individual and includes information such as your first and last
    name, address, email address and other online contact information. Anonymous
    Information does not identify you and can be combined with Anonymous
    Information about others.
  </p>

  <h2>1. INFORMATION WE COLLECT</h2>

  <p>
    We only collect Personal Information that you choose to provide through the
    Website, such as completing online forms to receive any Voyer Law online
    publications or when you contact Voyer Law through the Website.
  </p>
  <p>We collect your Anonymous Information as follows:</p>
  <ul>
    <li>
      Service statistics derived from cookies, including: what pages you viewed,
      how long you were on a particular page, how many times you have been to a
      page and whether you clicked on a link. A cookie is a small file we place
      on your computer and acts as a unique identifier. A cookie does not allow
      us access to your computer or any information about you, other than the
      data you choose to share with us.
    </li>

    <li>Country you are accessing the Website from.</li>

    <li>
      Type of web browser and operating system used to access the Website.
    </li>

    <li>
      The Website does not respond to web browser do not track signals or
      similar mechanisms.
    </li>

    <li>
      Information you submit through the Website with respect to your publishing
      agreement terms.
    </li>
  </ul>

  <h2>2. HOW WE USE YOUR INFORMATION</h2>

  <p>
    We use your Personal Information to provide publications to you that you
    request and to respond to any communication Voyer Law receives from you.
  </p>

  <p>
    We use your Anonymous Information externally in order to provide the Website
    and content thereon, and internally for statistical analysis purposes and to
    improve our offerings by tailoring them to customer needs.
  </p>

  <h2>3. WHO WE DISCLOSE INFORMATION TO</h2>

  <p>
    We disclose your Personal Information to third parties to respond to
    inquiries from law enforcement or to defend our rights or the rights of
    others.
  </p>

  <p>
    We disclose your Anonymous Information to the public generally via the
    Website and to third parties only so they may render such information into
    statistics that we may analyze.
  </p>

  <h2>4. THIRD PARTY SOFTWARE AND SERVICES</h2>

  <p>
    The Website may include links to third party services, including websites,
    that may disclose your Personal or Anonymous Information to third parties.
    Additionally, third party services may collect Personal Information about
    your online activities over time and across different websites. The Privacy
    Policy only applies to information that Voyer Law collects, uses or
    discloses and does not apply to the collection, use or disclosure of your
    information by third parties through third party services. Use of third
    party services may require you to accept a third party’s terms of
    service/use or privacy policy. It is your sole responsibility to determine
    whether any third party privacy policy or other agreement is acceptable to
    you.
  </p>

  <h2>5. UPDATES</h2>

  <p>
    Voyer Law reserves the right, at its sole discretion, to change or modify or
    to add or remove portions of the Privacy Policy at any time (“Updates”). We
    agree to notify you of Updates by a notification posted on
    videogamepublishing.com. You will be deemed to have accepted any Update by
    continuing to access the Website. Unless Voyer Law states otherwise, Updates
    are automatically effective thirty days after being posted on
    videogamepublishing.com.
  </p>

  <h2>6. CONTACT US</h2>

  <p>
    If you have questions or comments about the Privacy Policy or Voyer Law’s
    data collection in general, please contact us at info@voyerlaw.com
  </p>
</div>