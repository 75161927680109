import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Averages } from '../models/Averages.js';
import { data } from './data/data.js';


const getDataURL = "https://vidgamepubbackend.azurewebsites.net/api/getAllAgreements?code=0EZW0Sh7j7xjuXC6FIYFABhB2MVV0G4nJHdR4sTspvNjE9rDEDskMg==;";
const changeStatusURL = "https://vidgamepubbackend.azurewebsites.net/api/changeStatus?code=fvqP/8jjrv4JY3x/Nhn3BfvLhgkYSTivIkM5Thk0XKAQwbZErlusow==";


const submitAgreementURL = "https://vidgamepubbackend.azurewebsites.net/api/submitAgreement?";
const getAveragesURL = "https://pubcdn.blob.core.windows.net/averages/averages.json";
const calculateAveragesURL = "https://vidgamepubbackend.azurewebsites.net/api/calculateAverages?"
const APIKey = "vuNPfkmQNRClzaxtMdulF4pNwD1tSrOGz7AWf1PLteJdYkKKhgGZ6A==";
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }
  headers = {
    headers: {
      "x-functions-key": APIKey,
    }
  }
  agreements: any[];
  averages: Averages;
  data: any;
  async getData() {
    if (!this.data) {
      await this.generateData();
    }
    return this.data;
  }
  async getOneItem(id: string) {
    if (!this.data) {
      await this.generateData();
    }
    const result = this.data.data.find((e) => e.id === id);
    return result;
  }
  async getOneAgreement(id: string) {
    if (!this.agreements) {
      return null;
    }
    const result = this.agreements.find((e) => e.id === id);
    return result;
  }
  async getAllAgreements(refresh: boolean = false): Promise<any[]> {
    if (this.agreements && !refresh) {
      return this.agreements;
    }
    const agreements: any = await this.http.get(getDataURL, this.headers).toPromise();
    console.log(agreements);
    this.agreements = agreements;
    return this.agreements;
  }
  async getAllAgreementsStub(): Promise<any[]> {
    this.agreements = [
      {
        "advance": 100000,
        "id": "c4a42591-d115-4f2b-82a8-852ad421fd58",
        "ipOwnership": "Developer",
        "revenueShareDeveloper": 2200020202,
        "revenueSharePublisher": 420,
        "submissionDate": 1607298573154,
        "termType": "Fixed",
        "status": "New"
      }, {
        "advance": 111111,
        "id": "f1211dd5-b00b-4052-bbf0-9b36c9c56299",
        "ipOwnership": "Publisher",
        "revenueShareDeveloper": 11111,
        "revenueSharePublisher": 23123,
        "submissionDate": 1607298576734,
        "termType": "Variable",
        "status": "New"
      },
      {
        "advance": 100000,
        "id": "c4a42591-d115-4f2b-82a8-852ad421fd58876876",
        "ipOwnership": "Developer",
        "revenueShareDeveloper": 2200020202,
        "revenueSharePublisher": 420,
        "submissionDate": 1607298573154,
        "termType": "Fixed",
        "status": "Approved"
      }, {
        "advance": 111111,
        "id": "f1211dd5-b00b-4052-bbf0-9b36c9c5629998769876",
        "ipOwnership": "Publisher",
        "revenueShareDeveloper": 11111,
        "revenueSharePublisher": 23123,
        "submissionDate": 1607298576734,
        "termType": "Variable",
        "status": "Rejected"
      }
    ]
    return this.agreements;
  }
  async submitAgreement(agreement) {
    const response = await this.http.post(submitAgreementURL, agreement,
      this.headers).toPromise();
    console.log(response);
    return response;
  }
  async changeStatus(status, agreementID) {
    const response = await this.http.post(changeStatusURL, { status, agreementID },
      this.headers).toPromise();
    console.log("REsponse", response);
    this.agreements = null;
    return response;
  }
  async calculateAverages() {
    const response = await this.http.get(calculateAveragesURL, this.headers).toPromise();
    console.log(response);
    return response;
  }
  async getAverages() {
    console.log('Heloo')
    if (!this.averages) {
      const response: any = await this.http.get(getAveragesURL, this.headers).toPromise();
      console.log("Averages response: ", response);
      this.averages = response;
    }
    return this.averages;
  }

  async generateData() {
    if (!this.averages) {
      await this.getAverages();
    }

  const  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
    this.data = {
      data: [
        {
          id: 'Advance',
          tileInfo: {
            title: 'Advance',
            text: `The amount of money (in USD) paid by the publisher to the developer to fund game development, marketing etc.`,
            number: `${formatter.format(this.averages.meanAdvance)}`,
            numberText: 'Paid to developer on average'
          },
          detailedInfo: {
            description: ``,
            infoTiles: [
              {
                title: 'Mean advance amount:',
                number: `${formatter.format(this.averages.meanAdvance)}`,
              },
              {
                title: 'Median advance amount: ',
                number: `${formatter.format(this.averages.medianAdvance)}`,
              },
              {
                title: ' Lowest advance amount:',
                number: `${formatter.format(this.averages.minAdvance)}`,
              },
              {
                title: 'Highest advance amount:',
                number: `${formatter.format(this.averages.maxAdvance)}`,
              },
              {
                title: 'Percentage of deals without an advance: ',
                number: `${(this.averages.noAdvance * 100).toFixed(1)}%`,
                text: `In some publishing deals no advance is paid.  Typically seen where a game is close to completion and the developer only requires the publisher to assist with marketing and distribution.  
                          Expect a higher revenue share if no advance is paid (see Revenue Share section).`
              },
              {
                title: 'Advance paid in milestones:',
                number: `${(this.averages.advanceInMilestones * 100).toFixed(1)}%`,
                text: `Typically advances are paid in milestones (whether time based or technical), rather than as a lump sum payment.`
              },
              {
                title: 'Advance recouped by publisher:',
                number: `${(this.averages.advanceRecoupable * 100).toFixed(1)}%`,
                text: `The advance is either recoupable or non-recoupable.  If recoupable, the advance must be repaid to the publisher.  
                          If non-recoupable, the developer does not repay the advance.  `
              },
              {
                title: 'Developer receives revenue share while advance recouped:',
                number: `${(this.averages.revenueReceivedWhileRecoup * 100).toFixed(1)}%`,
                text: `Typically the developer receives a revenue share while the advance is recouped.  
                          If not, the developer will not receive any payments until the advance is fully recouped, which means that the developer may never see a dollar from the game should sales not be sufficient to recoup the advance.  `
              },
              {
                title: 'Royalty rate developer receives during advance recoup:',
                number: `${ this.averages.royaltyRateDuringRecoup.toFixed(1)}%`,
              },
              {
                title: 'Royalty rate developer receives after advance recouped:',
                number: `${ this.averages.royaltyRateAfterRecoup.toFixed(1)}%`,
              }
            ]
          },
        },
        {
          id: 'Revenue Share',
          tileInfo: {
            title: 'Revenue Share',
            text: `The percentage of game revenue paid by the publisher to the developer, typically calculated on a net revenue basis.`,
            number: `${this.averages.avgDeveloperRoyalty.toFixed(1)}%`,
            numberText: 'Paid to developer on average'
          },
          detailedInfo: {
            description: `
  How gross and net revenue are calculated is critical and results in different revenue share pay-outs to a developer.  It’s helpful to view net revenue as a list of amounts that the publisher is repaid for.  For example:
  
  Net revenue = gross revenue actually received by publisher relating to the game -
  (returns, chargebacks, platform fees, VAT, marketing costs, porting costs, localization costs and QA costs). 
   
  The fewer deductions made in arriving at the net revenue calculation, the more revenue to be paid to the developer.
  Or, viewed another way, the fewer deductions means that the publisher is willing to bear more of the costs.
                  
                  `,
            infoTiles: [
              {
                title: 'Revenue share if developer receives an advance:',
                number: `${this.averages.revenueShareWithAdvance.toFixed(1)}%`,
              },
              {
                title: 'Revenue share if developer does not receive an advance:',
                number: `${this.averages.revenueShareWithoutAdvance.toFixed(1)}%`,
              }
            ]
          },
        },
        {
          id: 'IP Ownership',
          tileInfo: {
            title: 'IP Ownership',
            text: `How often the developer retains ownership of the game and related intellectual property (copyrights, trademarks and patents).`,
            number: `${(this.averages.developerIPOwnershipPercentage * 100)}%`,
            numberText: 'Owned by developer on average'
          },
          detailedInfo: {
            description: `In most deals, the developer retains ownership of the game and related intellectual property (copyrights, trademarks and patents).  This is critical as, without IP ownership, 
                  the developer loses ownership of the game.  Our data clearly indicates that it’s standard for the developer to retain ownership of the game and related IP. `,
            infoTiles: [
              {
                title: 'IP owned by developer on average',
                number: `${(this.averages.developerIPOwnershipPercentage * 100).toFixed(1)}%`,
              },
              {
                title: 'IP transfer on breach:',
                number: `${(this.averages.transferOnBreachPercentage * 100).toFixed(1)}%`,
                text: `While a publisher may not own game IP at the start of a publishing agreement, 
                          in a relatively small number of publishing agreements the publisher can assert ownership of the game if the developer breaches the agreement.`
              },
            ]
          },
        },
        {
          id: 'Sequels',
          tileInfo: {
            title: 'Sequels',
            text: `The right of the publisher to publish (or negotiate to publish) one or more games made by the developer in the future.`,
            number: `${(this.averages.publisherSequelNegotiationRight * 100).toFixed(1)}%`,
            numberText: 'Publisher has a right to negotiate a sequel'
          },
          detailedInfo: {
            description: ``,
            infoTiles: [{
              title: 'Publisher has a  right to publish a sequel:',
              number: `${(this.averages.publisherSequelRight * 100).toFixed(1)}%`,
              text: `The first type of clause is problematic as it forces the developer to publish a game with the publisher in the future.
              It’s difficult for a developer to predict the future and a developer should not be forced to continue a relationship with a publisher, 
              especially if the relationship has fallen apart or preferred publishers are offering to publish the game.  This type of clause is worse where it allows 
              the publisher to publish the sequel on the same terms as the current publishing agreement, even though there may be better publishing offers available to them.
              For example, as a first time developer you may agree to a 50/50 revenue share but you don’t want to be forced into the same revenue share for the sequel, especially if it’s expected to be a hit.
             `
            },
            {
              title: 'Publisher has a right to negotiate a sequel:',
              number: `${(this.averages.publisherSequelNegotiationRight * 100).toFixed(1)}%`,
              text: `The second type of clause is preferred as it merely constitutes an obligation of the parties to negotiate the sequel publishing agreement in the future and, if the negotiation does not result 
              in an agreement, the developer is free to publish the game as it sees fit.`
            }]
          },
        },
        {
          id: 'Term_Duration',
          tileInfo: {
            title: 'Term/Duration',
            text: `How long the agreement lasts, typically measured based off the date of the game’s first commercial release.`,
            number: `${this.averages.avgFixedAgreementTermDuration.toFixed(1)} years`,
            numberText: 'Average agreement duration'
          },
          detailedInfo: {
            description: `The term of the publishing agreement should be limited to a fixed number of years, 
                  potentially with automatic renewal for a shorter duration, and should not be perpetual (meaning forever).  `,
            infoTiles: [
              {
                title: 'Percentage of agreements containing a fixed term: ',
                number: `${(this.averages.fixedAgreementsPercentage * 100).toFixed(1)}%`,
                text: `Fixed terms are for a set duration, usually a number of years, and may expire at the end of the term or automatically renew for a set period.`
              },
              {
                title: 'Percentage of agreements containing perpetual term:',
                number: `${(this.averages.perpetualAgreementsPercentage * 100).toFixed(1)}%`,
                text: `Perpetual terms mean that publishing rights will never revert back to the developer and, 
                          since the publishing agreement continues in perpetuity, can have the unintentional result of causing sequel rights and other clauses to also continue in perpetuity.  `
              }
            ]
          },
        },
        {
          id: 'Audit Right',
          tileInfo: {
            title: 'Audit Right',
            text: `Developer’s right to audit publisher’s books to confirm that the revenue share paid is accurate.`,
            number: `${(this.averages.auditRightPercentage * 100).toFixed(1)}%`,
            numberText: 'Developer has audit right'
          },
          detailedInfo: {
            description: ``,
            infoTiles: [{
              title: 'Right to audit',
              number: `${(this.averages.auditRightPercentage * 100).toFixed(1)}%`,
              text: `Developer’s right to audit publisher’s books to confirm that the revenue share paid is accurate.`
            }]
          },
        }
      ]
    }
  }
}
