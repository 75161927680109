import { Pipe, PipeTransform } from '@angular/core';

const TitleMap = {
  "advanceAmount": "Advance",
  "advancePaymentType": "Advance payment type",
  "advanceReceived": "Has the advance been received",
  "advanceRecoupable": "Is the advance recoupable",
  "advanceRecoupableBeforeRoyalty": "Is the adnvance recoupable before royalty",
  "agreementDuration": "Agreement duration",
  "agreementTermType": "Agreement Term Type",
  "developerAuditRight": "Developer has right to audit publishers books",
  "developerRoyalty": "Developer Royalty",
  "developerRoyaltyVariation": "Developer Royalty Variation", 
  "ipOwnership": "Ip Ownership",
  "publisherSequelRight": "Publisher rights for sequel",
  "revenueShareDeveloper": "Revenue share developer",
  "revenueSharePublisher": "Revenue share publisher",
  "status": "Status",
  "submissionDate": "Submission date",
  "termType": "Term Type",
  "developerRoyaltyDuringRecoup": "Developer royalty during recoup",
  "developerRoyaltyAfterRecoup": "Developer royalty after recoup"
}

@Pipe({
  name: 'filterAgreementEntries'
})
export class FilterAgreementEntriesPipe implements PipeTransform {

  exceptions = ["_id", "id", "status", "recaptchaReactive", "royaltyVariationInput"];

  transform(entries: any): any {
    console.log("AGREEMENT KEY: ", entries)
    return entries.filter((e) => !this.exceptions.includes(e.key)).map( (e) =>{
      return { key: this.normalizeTitle(e.key), value: this.normalizeValue(e.key, e.value)}
    })
  }
  normalizeTitle(title) {
    if(TitleMap[title]){
      return TitleMap[title];
    }
    return title;
  }
  normalizeValue(key, value) {
    switch (key) {
      case "advance":
        return `$${value}`;
      case "revenueShareDeveloper":
        return `${value}%`
      case "revenueSharePublisher":
        return `${value}%`
      case "revenueSharePublisher":
        return `${value}%`
      case "submissionDate":
        return new Date(value).toDateString();
      case "developerRoyalty":
        return `${value}%`
      default:
        return value;
    }
  }
}
