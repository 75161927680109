<nav>
    <div (click)="goBack()">BACK</div>
</nav>
<div *ngIf="fullAgreement" class="container">
      
        <ag-entry class="entry-container" Title="Advance" [Value]="fullAgreement.advanceAmount | currency"></ag-entry>
        <ag-entry class="entry-container" Title="Advance payment type" [Value]="fullAgreement.advancePaymentType"></ag-entry>
        <ag-entry class="entry-container" Title="Has the advance been received" [Value]="fullAgreement.advanceReceived"></ag-entry>
        <ag-entry class="entry-container" Title="Is the advance recoupable" [Value]="fullAgreement.advanceRecoupable"></ag-entry>
        <ag-entry class="entry-container" Title="Is the advance recoupable before royalty" [Value]="fullAgreement.advanceRecoupableBeforeRoyalty"></ag-entry>
        <ag-entry class="entry-container" Title="Agreement duration" [Value]="fullAgreement.agreementDuration + ' years'"></ag-entry>
        <ag-entry class="entry-container" Title="Agreement Term Type" [Value]="fullAgreement.agreementTermType"></ag-entry>
        <ag-entry class="entry-container" Title="Developer has right to audit publishers books" [Value]="fullAgreement.developerAuditRight"></ag-entry>
        <ag-entry class="entry-container" Title="Developer Royalty" [Value]="fullAgreement.developerRoyalty/100 | percent"></ag-entry>
        <ag-entry class="entry-container" Title="Developer royalty after recoup" [Value]="fullAgreement.developerRoyaltyAfterRecoup/100 | percent"></ag-entry>
        <ag-entry class="entry-container" Title="Developer royalty during recoup" [Value]="fullAgreement.developerRoyaltyDuringRecoup/100 | percent"></ag-entry>
        
        <div class="entry-container">
            <div class="entry">
                <div class="title">Developer Royalty Variation</div>
                <ng-container *ngFor="let entry of fullAgreement.developerRoyaltyVariation; let i = index">
                    <div class="title">Term {{i+1}}</div>
                    <div class="value">{{entry/100 | percent}}</div>
                </ng-container>
            </div>
        </div>

        <ag-entry class="entry-container" Title="Ip Ownership" [Value]="fullAgreement.ipOwnership"></ag-entry>
        <!-- <ag-entry class="entry-container" Title="Publisher has right to negotiate a sequel" [Value]="fullAgreement.publisherSequelNegotiationRight"></ag-entry> -->
        <ag-entry class="entry-container" Title="Publisher rights for sequel" [Value]="fullAgreement.publisherSequelRight"></ag-entry>
        <ag-entry class="entry-container" Title="Submission date" [Value]="fullAgreement.submissionDate | date"></ag-entry>
 
    <div class="control-buttons">
        <div (click)="changeStatus('New')" *ngIf="fullAgreement.status !== 'New'" class="button new">Set to "New"</div>
        <div (click)="changeStatus('Rejected')" *ngIf="fullAgreement.status !== 'Rejected'" class="button reject">Reject</div>
        <div (click)="changeStatus('Approved')" *ngIf="fullAgreement.status !== 'Approved'" class="button approve">Approve</div>
    </div>
    <div class="status"><span>Status: </span>{{fullAgreement.status}}</div>

</div>