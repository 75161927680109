import { Component, OnInit, Input } from '@angular/core';
import { TileData } from 'src/app/models/TileData';

@Component({
  selector: 'app-info-tile',
  templateUrl: './info-tile.component.html',
  styleUrls: ['./info-tile.component.sass']
})
export class InfoTileComponent implements OnInit {
  @Input() tile: TileData;

  constructor() { }

  ngOnInit(): void {
  }

}
