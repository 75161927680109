<nav>
    <div><a routerLink="/">HOME</a></div>
    <div><a routerLink="/submit-terms">PARTICIPATE</a></div>
    <a routerLink="/average-terms"><div>TERMS</div></a>
    <div><a routerLink="/about">ABOUT</a></div>
  </nav>
  <div class="container">
    <h1>TERMS OF USE</h1>

    <p>This website, videogamepublishing.com, including subdomains, (the “Website”) is operated by Voyer Law Corporation (“Voyer Law”). Your use of the Website is governed by this Terms of Use and a Privacy Policy (collectively, the “Terms”). You must agree to the Terms in order to access the Website.</p>
    
    <p>By accessing the Website you are agreeing to be bound by the Terms. If at any time you disagree with the Terms you agree to immediately stop accessing the Website.</p>
    
    <h2>1. UPDATES</h2>
    
    <p>Voyer Law reserves the right, at its sole discretion, to change or modify or to add or remove portions of the Terms at any time (“Updates”). Voyer Law agrees to notify you of Updates by a notification posted on videogamepublishing.com. You will be deemed to have accepted any Update by continuing to access the Website. Unless Voyer Law states otherwise, Updates are automatically effective thirty days after being posted on videogamepublishing.com.</p>
    
    <h2>2. NO LAWYER-CLIENT RELATIONSHIP</h2>
    
    <p>Accessing the website or contacting any lawyer or other personnel through contact information contained on the Website, including but not limited to email and telephone, does not create a lawyer-client relationship or any other fiduciary relationship. Communications made through the Website are not guaranteed to be confidential and Voyer Law expressly disclaims any warranties, express or implied, concerning such confidentiality.</p>
    
    <h2>3. NO LEGAL ADVICE</h2>
    
    <p>Website content does not constitute legal advice and cannot be relied upon as accurate, complete, current, reliable or fit for your particular situation. Please contact Voyer Law or another qualified legal professional for advice on your particular situation.</p>
    
    <h2>4. LICENSE</h2>
    
    Voyer Law grants you a non-exclusive, non-transferable, revocable (at any time and without cause) licence to access the Website and to display, download and print copies of the Website content for your own use. Unless expressly stated in this agreement, no additional licence to the Website content is granted to you.
    
    <h2>5. RESTRICTED ACTIVITIES</h2>
    
    <p>You agree to use the Website in a manner consistent with any and all applicable laws and regulations and to not do the following in connection with the Website:</p>
    <ol type="a">
    <li>use any robot, spider, site search/retrieval application or other manual or automatic device or process to retrieve, index, data-mine or in any way reproduce or circumvent the navigational structure or presentation of the Website;</li>
    
    <li>interfere with or disrupt the Website or servers or networks connected to the Website;</li>
    
    <li>post, email or otherwise transmit any material that contains software, viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
    
    <li>modify, adapt, sublicence, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Website or cause others to do so; or</li>
    
    <li>post material, or make any statement that is, defamatory, trade libelous, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, racially offensive or illegal.</li>
    </ol>
    <h2>6. YOUR REPRESENTATIONS AND WARRANTIES</h2>
    
   <p> You represent and warrant that all information disclosed to Voyer Law is accurate, that you have authority and authorization to disclose this information to Voyer Law and your disclosure of this information does not infringe or violate third party rights including but not limited to: (a) contractual rights; (b) copyright, patent, trademark, or trade secret rights; (c) privacy rights; (d) publicity rights; or (e) confidential information. Any information that Voyer Law collects from you is subject to the Terms.</p>
    
    <h2>7. DISCLAIMER</h2>
    
    <p>THE WEBSITE IS LICENCED TO YOU “AS IS” WITHOUT ANY WARRANTY OF ANY KIND. VOYER LAW MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, ABOUT THE ACCURACY, COMPLETENESS, CURRENCY, RELIABILITY, TIMELINESS, QUALITY MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE WEBSITE AND CONTENT, TO THE EXTENT AUTHORIZED BY LAW. WITHOUT LIMITING THE FOREGOING, VOYER LAW MAKES NO REPRESENTATIONS OR WARRANTIES THAT USE OF THE WEBSITE WILL NOT INFRINGE ANY COPYRIGHT, PATENT OR TRADEMARK OR OTHER RIGHTS HELD BY A THIRD PARTY. FURTHER AND WITHOUT LIMITING THE FOREGOING, VOYER LAW MAKES NO REPRESENTATIONS OR WARRANTIES THAT THE WEBSITE AND CONTENT WILL MEET YOUR REQUIREMENTS OR WILL BE FREE OF ERRORS, OMISSIONS, INACCURACIES OR WILL NOT HARM YOUR COMPUTER OR MOBILE DEVICE. YOU ASSUME RESPONSIBILITY FOR DETERMINING WHETHER THE WEBSITE AND ITS CONTENT IS APPROPRIATE FOR YOU. YOU BEAR THE ENTIRE RISK IN ACCESSING AND USING THE WEBSITE AND CONTENT.</p>
    
    <h2>8. GOVERNING LAW & DISPUTE RESOLUTION</h2>
    
    <h3>8.1 Governing Law.</h3><p>  The Terms are governed by the laws of the Province of British Columbia without regard to choice of law principles.</p>
    
    <h3>8.2 Dispute Resolution.</h3><p>  If any disagreement or dispute arising out of or relating to the Terms, or breach thereof, (a “Dispute”) occurs, you and Voyer Law agree to first attempt to resolve the Dispute informally for a period of at least 30 days commencing on the date you notify Voyer Law of the Dispute. If the Dispute is not resolved, you and Voyer Law agree to submit the Dispute to settlement by final and binding arbitration to be conducted in Vancouver, British Columbia. The arbitration will be commenced and conducted in accordance with the Commercial Rules of the American Arbitration Association (the “Rules”). Your arbitration fees and your share of the arbitrator’s compensation will be governed by and, where appropriate, limited by the Rules. If the law of your jurisdiction prohibits the provisions of the foregoing, the arbitration will be commenced and conducted in accordance with the Rules of Arbitration of the International Chamber of Commerce.</p>
    
    <h3>8.3 Exceptions to Informal Resolution and Arbitration.</h3><p>  The following Disputes are not subject to the above provisions concerning informal Dispute resolution and arbitration: (a) Disputes concerning the enforcement, protection or validity of intellectual property rights belonging to you or Voyer Law; (b) Disputes concerning allegations of invasion of privacy, piracy, theft or unauthorized use of the Website (including violation of the Terms of Use or Privacy Policy); and (c) claims for injunctive relief.</p>
    
    <h3>8.4 Severability.</h3><p>  To the extent any section, clause, provision or sentence or part thereof of the Terms of Use is determined to be illegal, invalid or unenforceable by competent authority in any jurisdiction, then that portion will be severed and the remainder of the Terms of Use will be given full force and effect.</p>
    
    <h3>8.5 No Waiver.</h3><p>  Voyer Law’s failure to assert or enforce any right contained in the Terms of Use will not constitute a waiver of that right.</p>
    
    <h3>8.6 Entire Agreement.</h3><p>  The Terms of Use constitutes the entire understanding and agreement between the parties with respect to the subject matter hereof and supersedes any prior oral or written agreements, communications, representations or undertakings provided. To the extent the Terms of Use conflicts with the provisions of the Privacy Policy, the conflicting provisions of the Privacy Policy will govern.</p>
    

  </div>