import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitTermsComponent } from './submit-agreement/pages/submit-terms/submit-terms.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CurrencyPipe } from '@angular/common';



@NgModule({
  declarations: [
    SubmitTermsComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule
  ],
  exports: [
    SubmitTermsComponent
  ],
  providers: [
    CurrencyPipe
  ]
})
export class SubmitAgreementModule { }
