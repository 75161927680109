import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AdminComponent } from '../pages/admin/admin.component';
import { FullAgreementComponent } from '../pages/full-agreement/full-agreement.component';
import { LoginComponent } from '../pages/login/login.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterAgreementEntriesPipe } from '../pipes/filter-agreement-entries/filter-agreement-entries.pipe';
import { FilterAgreementsPipe } from '../pipes/filter-agreement/filter-agreements.pipe';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AgreementEntryComponent } from '../components/agreement-entry/agreement-entry.component';



@NgModule({
  declarations: [
    AdminComponent,
    FullAgreementComponent,
    LoginComponent,
    FilterAgreementsPipe,
    FilterAgreementEntriesPipe,
    AgreementEntryComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
  ],
  exports: [
    AdminComponent,
    FullAgreementComponent,
    LoginComponent
  ],
  providers:[
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeOsCMaAAAAACvri7dOlZQ8KXE-ZNKXYFRELbTH" },
    AuthGuardService,
  ]
})
export class AdminDashboardModule { }
