import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin/admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})
export class AdminComponent implements OnInit {

  constructor(private dataService: DataService, private router: Router, private adminService: AdminService) { }
  agreements: any[];
  filterBy: string = "New";
  isAgreementOpen: boolean = false;
  isCalculating: boolean = false;
  isRefreshing: boolean = false;
  calcResult: string;
  newSubmissionsPresent: boolean = true;

  ngOnInit(): void {
    this.loadAgreements();
    this.filterBy =  this.adminService.filterBy;
  }

  refresh(){
    this.isRefreshing = true;
    this.dataService.getAllAgreements(true).then((response) =>{
      this.agreements = response;
      this.checkForNewSubmissions();
      this.isRefreshing = false;
      
    });
  }

  loadAgreements(){
    this.isRefreshing = true;
    this.dataService.getAllAgreements().then((response) =>{
      this.agreements = response;
      this.checkForNewSubmissions();
      this.isRefreshing = false;
    });
  }

  checkForNewSubmissions(){
    this.newSubmissionsPresent = this.agreements.some( (submission) =>{
      return submission.status === "New"
    })
  }

  changeFilterBy(status){
    console.log(status)
    this.filterBy = status;
    this.adminService.filterBy = status;
  }

  async calculateAverages(){
    this.isCalculating = true;
    try{
      await this.dataService.calculateAverages();
      this.calcResult = "Calculation successful";
    }
    catch(error){
      console.log(error);
      this.calcResult = "Calculation failed";
    }
    this.isCalculating = false;
  
  }

}