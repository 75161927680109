import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ag-entry',
  templateUrl: './agreement-entry.component.html',
  styleUrls: ['./agreement-entry.component.sass']
})
export class AgreementEntryComponent implements OnInit {

  @Input() Title: string;
  @Input() Value: string;

  constructor() { }

  ngOnInit(): void {
  }

}
